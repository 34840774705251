@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components{
    .menu-item-active{
        @apply bg-gray-700 text-white;
    }
}
